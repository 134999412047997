.container { height: 100% }

form {
  background-color: rgba(0,0,0,.01);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  padding: 2em 3em 1em 3em;
}
table#calendar td {
  height: 10rem;
  position: relative;
  width: 14.3%;
}
table#calendar td span.day {
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 7.4rem;
  top: 0.75rem;
  width: 28px;
}
table#calendar td span.extraCal {
  background-color: rgba(240, 240, 240, .65);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

table#calendar td ul.events { padding-top: 2rem }
table#calendar td ul.events li.list-group-item { padding: 0 }
table#calendar td ul.events li.list-group-item a {
  color: rgba(0, 0, 0, .75);
  display: block;
  font-size: .7rem;
  padding: .5rem;
}
table#calendar td ul.events li.list-group-item a:hover { text-decoration: none }
table#calendar td ul.events li.list-group-item.bombole { background-color: rgba(254, 214, 0, .1) }
table#calendar td ul.events li.list-group-item.enel { background-color: rgba(65, 185, 230, .1) }
table#calendar td ul.events li.list-group-item.fitness { background-color: rgba(255, 0, 0, .1) }
table#calendar td ul.events li.list-group-item.mutuo { background-color: rgba(255, 98, 0, .1) }
table#calendar td ul.events li.list-group-item.tiscali { background-color: rgba(0, 152, 167, .1) }
.form-control:focus { box-shadow: inset 0 1px 2px rgba(0,0,0,.075), 0 0 5px rgba(128,189,255,.5) !important }
.card-text { margin-bottom: .5rem}
.card-text b {
  display: block;
  font-size: .7rem;
}
.card-text span { color: #898989 }
.modal {
  display: block !important;
  overflow-y: auto;
}
.modal-bg {
  background: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal fieldset {
  background-color: rgba(0, 0, 0, .05);
  margin-bottom: 1rem;
  padding: 1rem;
}
.modal fieldset div.form-group:last-child { margin-bottom: 0 }

.modal .form-group .invalid-feedback { display: block }

/** BOOTSTRAP + REACT-DATEPICKER: CSS FIX */
.react-datepicker.showTimeSelect { width: 313px }
.react-datepicker__input-container {
  height: 38px;
  overflow: hidden;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { padding: 0 !important }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item { white-space: nowrap !important }

/** BOOTSTRAP + REACT-PDF: CSS FIX */
.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}
tbody { color: #898989 }
tbody td.text-right { white-space:nowrap }

thead th {
  cursor: pointer;
  white-space: nowrap;
}
html, body, #root { height: 100% }

#root > .container { padding-top: 60px }

#menuTop a i.material-icons {
  font-size: 1rem;
  vertical-align: text-top;
}

.btn-sm i.material-icons { font-size: .875rem }

.loading {
  -webkit-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.navbar .nav-item:first-child { padding-left: 2rem }
.navbar .nav-item .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

#title h2 span.badge {
  font-size: .8rem;
  font-weight: normal;
}
