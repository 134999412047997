.modal {
  display: block !important;
  overflow-y: auto;
}
.modal-bg {
  background: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal fieldset {
  background-color: rgba(0, 0, 0, .05);
  margin-bottom: 1rem;
  padding: 1rem;
}
.modal fieldset div.form-group:last-child { margin-bottom: 0 }

.modal .form-group .invalid-feedback { display: block }

/** BOOTSTRAP + REACT-DATEPICKER: CSS FIX */
.react-datepicker.showTimeSelect { width: 313px }
.react-datepicker__input-container {
  height: 38px;
  overflow: hidden;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { padding: 0 !important }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item { white-space: nowrap !important }

/** BOOTSTRAP + REACT-PDF: CSS FIX */
.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}