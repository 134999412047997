html, body, #root { height: 100% }

#root > .container { padding-top: 60px }

#menuTop a i.material-icons {
  font-size: 1rem;
  vertical-align: text-top;
}

.btn-sm i.material-icons { font-size: .875rem }

.loading {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.navbar .nav-item:first-child { padding-left: 2rem }
.navbar .nav-item .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

#title h2 span.badge {
  font-size: .8rem;
  font-weight: normal;
}