table#calendar td {
  height: 10rem;
  position: relative;
  width: 14.3%;
}
table#calendar td span.day {
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 7.4rem;
  top: 0.75rem;
  width: 28px;
}
table#calendar td span.extraCal {
  background-color: rgba(240, 240, 240, .65);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

table#calendar td ul.events { padding-top: 2rem }
table#calendar td ul.events li.list-group-item { padding: 0 }
table#calendar td ul.events li.list-group-item a {
  color: rgba(0, 0, 0, .75);
  display: block;
  font-size: .7rem;
  padding: .5rem;
}
table#calendar td ul.events li.list-group-item a:hover { text-decoration: none }
table#calendar td ul.events li.list-group-item.bombole { background-color: rgba(254, 214, 0, .1) }
table#calendar td ul.events li.list-group-item.enel { background-color: rgba(65, 185, 230, .1) }
table#calendar td ul.events li.list-group-item.fitness { background-color: rgba(255, 0, 0, .1) }
table#calendar td ul.events li.list-group-item.mutuo { background-color: rgba(255, 98, 0, .1) }
table#calendar td ul.events li.list-group-item.tiscali { background-color: rgba(0, 152, 167, .1) }